import React from 'react';
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import TopHeader from '../../components/topheader';
import { getregistrationurl,getrecentregistrationurl,GetRegistration } from '../../services/api.registrations';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
//import DT from 'datatables.net-bs5';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import { Card, CardBody, CardHeader, CardTitle,Dropdown } from 'react-bootstrap';

const columns = [
  { data: 'id' },
  { data: 'network' },
  { data: 'referenceNumber' },
  { data: 'firstname' },
  { data: 'lastname' },
  { data: 'idType' },
  { data: 'idNumber' },
  { data: 'createDate' },
  { data: 'status' }

];




class SimPackRegistrationDropDown extends React.Component{

  constructor(props){
    super(props);
  }

  render(){
    return (<Dropdown className='dropdown'>
      <Dropdown.Toggle className='btn btn-success dropdown-toggle' type='button'  data-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
      Action
      <span className='caret'></span>
      </Dropdown.Toggle>
      <Dropdown.Menu className='dropdown-menu'>
      <Dropdown.Item onClick=''>Update Registration</Dropdown.Item>
      <Dropdown.Item onClick=''>View Registration</Dropdown.Item>
      </Dropdown.Menu>
      </Dropdown>);
      }
}

const token = localStorage.getItem("token")
const headers= {"Authorization":"Bearer "+token};
class Registrations extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
        const token = localStorage.getItem("token")
        const headers= {"Authorization":"Bearer "+token};
        this.state={"redirect":"",data:[],"module":"registrations","headers":headers}
        DataTable.use(DT);
    }


    componentDidMount(){
      
    }


    
  
  
 
 

    render(){
        
        return (<div>



            <div id="container">
            
             
                
            <div className="sidebar">
            
            <div className="logo">
            
            
            <div className="logo-content">
                   <img src={logo}   />
                    
                   
                </div>
            
            </div>
            
            
            <Sidebar />
            
            </div>
                <div className="main">
            
                 
                 <TopHeader />
            
            
                  <div id="content">
            
            
                    <div className="actions">
            
                      <div className="title">
                        <h4 className="text-muted my-2">
                          <i className="icon-person-outline"></i>
                          <span>Registrations</span>
                        </h4>
                      </div>
            
                      <div className="btns">
                        <button type="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="filters" title="Filter" className="btn btn-outline-secondary me-2">
                          <i className="icon-funnel-outline"></i>&nbsp;
                          <i className="icon-chevron-down-outline small"></i>
                        </button>
            
                        <a className="btn btn-primary btn-icon ms-1">
                          <i className="icon-add-circle-outline"></i>
                          <span>New Rica Registration</span>
                        </a>
                      </div>
            
                    </div>
            
            
                    <div id="filters" className="collapse mx-3">
                      
            
                       
                        <div className="card card-block p-3">
            
                          <div className="row">
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label">Search</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[search]"  placeholder="User name" id="search" />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label">Email</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[email]" placeholder="Email" id="Email" />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label">Phone number</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[phone_number]" placeholder="Phone number" id="phone_number" />
                                </div>
                              </div>
                            </div>
            
            
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label">Status</label>
                                <div className="col-sm-10">
                                  <select name="filter[status]" id="filter-by-status" className="form-control">
                                    <option value="">- All -</option>
                                    <option value="active" data-v-option>Active</option>
                                    <option value="inactive" data-v-option>Inactive</option>
                                  </select>
                                </div>
                              </div>
                            </div>
            
                            <div className="col-sm-2">
                              <button type="submit" id="button-filter" className="btn btn-primary btn-icon">
                                <i className="icon-funnel-outline"></i> Filter
                              </button>
                            </div>
                          </div>
            
                        </div>
                      
                    </div>
            
                    <div id="main-content">
            
                      <div className="users card-block" data-v-users>
                        
            
                          <div className="bulk-actions px-2 mb-2" style={{display:"none"}}>
                            
                            <button type="submit" name="action" value="delete" aria-expanded="false" title="Delete selected" className="btn btn-outline-secondary bg-body-tertiary text-danger">
                              <i className="icon-trash-outline"></i> Delete selected
                            </button>
                          </div>
            
                          <div style={{ height: "100%", width: 1000,overflowX:'scroll' }}>

<Card>
  <CardHeader>Simpack Registrations</CardHeader>
  <CardBody>



  <DataTable 
            
            ajax={{
              url:getregistrationurl,
              headers:this.state.headers,
              type:'POST'
               
              
            }}

            columns={columns} 
            className="display"
            slots={{
              5: (data, row) => (
                
                data=="N"?"National ID":"Passport"
              ),
              8: (data, row) => (
                
                data
              )
          }}
          options={{
            responsive: true,
            select: true,
            processing:true,
            serverSide:true,
            pageLength: 10
             
            
        }}>
              <thead>
                <tr>
                    <th>Id</th>
                    <th>Network</th>
                    <th>Simpack reference</th>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>ID Type</th>
                    <th>ID Number</th>
                    <th>Create Date</th>
                    <th>Status</th>
                  
                   
                </tr>
            </thead>
        </DataTable>  
 
        </CardBody>
        </Card>


      </div>
                      
                      
                      
                      
                      </div>
            
            
            
                     
            
                      
                    </div>
            
            
                  </div>
            
            
                </div>
              </div>
            
            
                    </div>);

    }
}


export default Registrations;