import axios from "axios";
import Config from "../config";
import { useSelector,useDispatch } from "react-redux";
import { forcelogout } from "../components/forcelogout";


const getrecentcredentialsurl=Config.rooturl+"/api/v1/Credentials/Fetch/Recent";
const getcredentialsurl=Config.rooturl+"/api/v1/Credentials/Fetch/All";

function UpdateStatus(id){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Credentials/UpdateStatus/"+id;
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}



function UpdateCred(id,username,password,network,groupName){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    var data={"id":id,"username":username,"password":password,"network":network,"groupName":groupName}
    const url=Config.rooturl+"/api/v1/Credentials/UpdateCredentials";
    console.log(headers);
    console.log(data)
    return axios.post(url,data,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}



function UpdatePassword(id,password){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    var data={"id":id,"password":password}
    const url=Config.rooturl+"/api/v1/Credentials/UpdatePassword";
    console.log(headers);
    return axios.post(url,data,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}

function GetCredentialsById(id){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Credentials/FetchById/"+id;
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}


function GetCredentialsDetailById(id){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Credentials/FetchDetailById/"+id;
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}

function GetAllCredentials(){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Credentials/Fetch/All";
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}

export{
    getcredentialsurl,
    getrecentcredentialsurl,
    GetCredentialsById,
    GetCredentialsDetailById,
    GetAllCredentials,
    UpdateStatus,
    UpdatePassword,
    UpdateCred
}