import React from 'react';
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import TopHeader from '../../components/topheader';
import Notification from '../../components/notification';
import { GetStats } from '../../services/api.stats';
import {  getregistrationurl,getrecentregistrationurl } from '../../services/api.registrations';
import {  getrecentagenturl } from '../../services/api.agent';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
//import DT from 'datatables.net-bs5';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';

const columnsreg = [
  { data: 'id' },
  { data: 'network' },
  { data: 'referenceNumber' },
  { data: 'firstname' },
  { data: 'lastname' },
  { data: 'phone' },
  { data: 'addressLine1' },
  { data: 'idType' },
  { data: 'idNumber' },
  

];


const columns = [
  { data: 'id' },
  { data: 'username' },
  { data: 'firstname' },
  { data: 'lastname' },
  { data: 'email' },
  { data: 'phone' },
  

];



class Dashboard extends React.Component{



    constructor(props){
        super(props);
        this.props = props;
        let token = localStorage.getItem("token")
        let headers= {"Authorization":"Bearer "+token};
        this.state={"agents":0,"admin":0,"registrations":0,"headers":headers}
        DataTable.use(DT);

    }

    componentDidMount(){
       
      GetStats().then(e=>{
        console.log(e);
        this.setState({"agents":e.agentCount,"admin":e.adminCount,"registrations":e.registrationCount})

      })
    }

    render(){
        return(<div>
            <div id="container">

<div className="sidebar">

  <div className="logo">

  
  <div className="logo-content">
         <img src={logo}   />
          
         
      </div>

  </div>

 
 <Sidebar />

</div>

<div className="main">

  <TopHeader />

  <div className="p-4">

    {/**<Notification /> */}

    <div className="grid-stack">

      <div className="grid-stack-item stack-registrations" gs-w="3" gs-h="2" data-v-component-notifications>
        
        <div className="grid-stack-item-content">
          <div className="card">
            <div className="card-body card-block info">
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">
                  <i className="la la-times"></i>
                </span>
              </button>
              <h5 className="card-title">Registrations</h5>

              <i className="la la-phone-square icon bg-orange text-white d-inline-block "></i>
              <h3 className="number d-inline-block" data-v-notification-orders-processing-count>{this.state.registrations}</h3>

              <div className="card-text float-end">
                <ul className="list-unstyled">
                  <li data-v-orders>
                    <a href="" className="text-body">
                      <strong data-v-notification-orders-processing-count>0</strong>
                      <span data-v-orders-name>Processing</span>
                    </a>
                  </li>
                  <li data-v-orders>
                    <a href="" className="text-body">
                      <strong data-v-notification-orders-complete-count>0</strong>
                      <span data-v-orders-name>Complete</span>
                    </a>
                  </li>
                  <li data-v-orders>
                    <a href="" className="text-body">
                      <strong data-v-notification-orders-processed-count>0</strong>
                      <span data-v-orders-name>Processed</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid-stack-item stack-users" gs-w="3" gs-h="2" data-v-component-notifications>

        <div className="grid-stack-item-content">
          <div className="card">
            <div className="card-body card-block info">
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">
                  <i className="la la-times"></i>
                </span>
              </button>

              <h5 className="card-title">Agents</h5>

              <i className="la la-user icon bg-purple text-white d-inline-block"></i>
              <h3 className="number d-inline-block" data-v-notification-users-new-count>{this.state.agents}</h3>

              <div className="card-text float-end">
                <ul className="list-unstyled">
                  <li>
                    <a href="" className="text-body">
                      <strong data-v-notification-users-month-count>0</strong> this month
                    </a>
                  </li>
                  <li>
                    <a href="" className="text-body">
                      <strong data-v-notification-users-year-count>1</strong> this year
                    </a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="grid-stack-item stack-administrators" gs-w="3" gs-h="2" data-v-component-notifications>
        
        <div className="grid-stack-item-content">
          <div className="card">
            <div className="card-body card-block info">
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">
                  <i className="la la-times"></i>
                </span>
              </button>
              <h5 className="card-title">Administrators</h5>

              <i className="la la-phone-square icon bg-orange text-white d-inline-block "></i>
              <h3 className="number d-inline-block" data-v-notification-orders-processing-count>{this.state.admin}</h3>

              <div className="card-text float-end">
                <ul className="list-unstyled">
                  <li>
                    <a href="" className="text-body">
                      <strong data-v-notification-users-month-count>0</strong> this month
                    </a>
                  </li>
                  <li>
                    <a href="" className="text-body">
                      <strong data-v-notification-users-year-count>1</strong> this year
                    </a>
                  </li>
                </ul>
              </div>
            
            
            </div>
          </div>
        </div>
      </div>


      <div className="grid-stack-item stack-sales" gs-w="12" gs-h="4" data-v-component-stats>

        <div className="grid-stack-item-content">
          <div className="card">
            <div className="card-header">
              Sim Registrations

              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">
                  <i className="la la-times"></i>
                </span>
              </button>

            </div>


            <div className="card-body">

            <DataTable 
            
            ajax={{
              serverSide: true,
              url:getrecentregistrationurl,
              headers:this.state.headers
              
            }}

            columns={columnsreg} 
            className="display"
            options={{
                responsive: true,
                select: true,
                processing:true
            }}>
             <thead>
                <tr>
                    <th>Id</th>
                    <th>Network</th>
                    <th>Simpack reference</th>
                    <th>First name</th>
                    <th>Last name</th>
                   
                    <th>Phone</th>
                    <th>Address</th>
                    <th>ID Type</th>
                    <th>ID Number</th>
              
                </tr>
                </thead>
        </DataTable>  
        
              
                        </div>
                    </div>
                </div>
      </div>
 

     


      <div className="grid-stack-item stack-new-agent" gs-w="3" gs-h="4" data-v-component-users>

        <div className="grid-stack-item-content">

          <div className="card">
            <div className="card-header">
             New Agents

              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">
                  <i className="la la-times"></i>
                </span>
              </button>
            </div>
            <div className="card-body">
           <DataTable 
            
            ajax={{
              serverSide: true,
              url:getrecentagenturl,
              headers:this.state.headers
              
            }}

            columns={columns} 
              className="display"
              

            options={{
                responsive: true,
                select: true,
                processing:true
            }}>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Username</th>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Email</th>
                    <th>Phone</th>
                  
                  
                   
                </tr>
            </thead>
        </DataTable>  


            </div>
            
          </div>
        </div>
      </div>

     
    </div>
  </div>
</div>
</div>
        </div>)
    }
}

export default Dashboard;