import React from 'react';


class Sidebar extends React.Component{

    constructor(props){
        super(props);
        this.props = props;

    }


    render(){

        return(<nav className="navbar navbar-expand-sm">

        <div className="logo">
         
          <a href="/admin" className="img">
           
          </a>
        </div>
    
    
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
    
         
    
        <div className="collapse navbar-collapse" id="sidebarNav">
    
          <ul className="nav navbar-nav flex-column" data-v-menu>
    
            <li className="nav-item" data-v-menu-item>
              <a className="nav-link d-none d-md-block " href="/dashboard" data-v-menu-item-url>
                <i className="icon-pulse-outline"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>Dashboard</span>
              
                <span className="badge badge-primary"></span>
              </a>
    
              <a className="nav-link mobile d-block d-sm-none" href="/dashboard" data-v-menu-item-url>
                <i className="icon-pulse-outline"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>Dashboard</span>
                
                <span className="badge badge-primary"></span>
              </a>
            </li>
    
             
    

            <li className="nav-item " data-v-menu-item>
              <a className="nav-link d-none d-md-block  items" href="/admin" data-v-menu-item-url title="Users" data-target="#menu-users">
                <i className="icon-people-outline"></i>
                <span className="title" data-v-menu-item-name>Administrators</span>
              </a>
              <a className="nav-link mobile d-block d-sm-none items" href="/admin" data-v-menu-item-url title="Users" data-bs-toggle="dropdown" data-target="#menu-users">
                <i className="icon-people-outline"></i>
                <span className="title" data-v-menu-item-name>Administrators</span>
              </a>
              <ul className="dropdown-menu sub-menu fade " data-v-submenu>
                <li className="nav-item " data-v-submenu-item>
                  <a href="/admin" className="nav-link " data-v-submenu-item-url>
                    <i className="la la-user" data-v-submenu-item-icon></i>
                    <span data-v-submenu-item-name>View Administrators</span>
                  </a>
                </li>
                <li className="nav-item " data-v-submenu-item>
                  <a href="/admin/add" className="nav-link " data-v-submenu-item-url>
                    <i className="la la-plus" data-v-submenu-item-icon></i>
                    <span data-v-submenu-item-name>Add new administrator</span>
                  </a>
                </li>
            
              </ul>
            </li>



            <li className="nav-item " data-v-menu-item>
              <a className="nav-link d-none d-md-block  items" href="/agent" data-v-menu-item-url title="Users" data-target="#menu-users">
                <i className="icon-people-circle-outline"></i>
                <span className="title" data-v-menu-item-name>Agents</span>
              </a>
              <a className="nav-link mobile d-block d-sm-none items" href="/agent" data-v-menu-item-url title="Users" data-bs-toggle="dropdown" data-target="#menu-users">
                <i className="icon-people-circle-outline"></i>
                <span className="title" data-v-menu-item-name>Agents</span>
              </a>
              <ul className="dropdown-menu sub-menu fade " data-v-submenu>
                <li className="nav-item " data-v-submenu-item>
                  <a href="/agent" className="nav-link " data-v-submenu-item-url>
                    <i className="la la-users" data-v-submenu-item-icon></i>
                    <span data-v-submenu-item-name>View Agents</span>
                  </a>
                </li>
                <li className="nav-item " data-v-submenu-item>
                  <a href="/agent/add" className="nav-link " data-v-submenu-item-url>
                    <i className="la la-plus" data-v-submenu-item-icon></i>
                    <span data-v-submenu-item-name>Add new agent</span>
                  </a>
                </li>
                <li className="nav-item  heading" data-v-submenu-item>
                  <a href="#" className="nav-link " data-v-submenu-item-url>
                    <span data-v-submenu-item-name>Roles</span>
                  </a>
                </li>
                <li className="nav-item " data-v-submenu-item>
                  <a href="/agent/roles" className="nav-link " data-v-submenu-item-url>
                    <i className="la la-user-friends" data-v-submenu-item-icon></i>
                    <span data-v-submenu-item-name>View agent roles</span>
                  </a>
                </li>
                <li className="nav-item " data-v-submenu-item>
                  <a href="/agent/addrole" className="nav-link " data-v-submenu-item-url>
                    <i className="la la-plus" data-v-submenu-item-icon></i>
                    <span data-v-submenu-item-name>Add new agent role</span>
                  </a>
                </li>
              </ul>
            </li>


              
            <li className="nav-item " data-v-menu-item>
              <a className="nav-link d-none d-md-block  items" href="/credentials">
                <i className="icon-people-circle-outline"></i>
                <span className="title" data-v-menu-item-name>Credentials</span>
              </a>
              <a className="nav-link mobile d-block d-sm-none items" href="/credentials" data-bs-toggle="dropdown">
                <i className="icon-people-circle-outline"></i>
                <span className="title" data-v-menu-item-name>Credentials</span>
              </a>
              <ul className="dropdown-menu sub-menu fade">
                <li className="nav-item">
                  <a href="/credentials" className="nav-link">
                    <i className="la la-users" data-v-submenu-item-icon></i>
                    <span data-v-submenu-item-name>View Credentials</span>
                  </a>
                </li>
                <li className="nav-item " data-v-submenu-item>
                  <a href="/credentials/add" className="nav-link " data-v-submenu-item-url>
                    <i className="la la-plus" data-v-submenu-item-icon></i>
                    <span data-v-submenu-item-name>Add Credentials</span>
                  </a>
                </li>
                
                
              </ul>
            </li>

            <li className="nav-item" data-v-menu-item>
              <a className="nav-link d-none d-md-block " href="/simpack" data-v-menu-item-url>
                <i className="icon-grid"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>Sim Packs</span>
              
                <span className="badge badge-primary"></span>
              </a>
    
              <a className="nav-link mobile d-block d-sm-none" href="/simpack" data-v-menu-item-url>
                <i className="icon-grid"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>Sim Packs</span>
                
                <span className="badge badge-primary"></span>
              </a>
            </li>


            <li className="nav-item " data-v-menu-item>
              <a className="nav-link d-none d-md-block  items" href="/registrations" data-v-menu-item-url title="Users" data-target="#menu-users">
                <i className="icon-phone-portrait-outline"></i>
                <span className="title" data-v-menu-item-name>Registrations</span>
              </a>
              <a className="nav-link mobile d-block d-sm-none items" href="/registrations" data-v-menu-item-url title="Users" data-bs-toggle="dropdown" data-target="#menu-users">
                <i className="icon-phone-portrait-outline"></i>
                <span className="title" data-v-menu-item-name>Registrations</span>
              </a>
              <ul className="dropdown-menu sub-menu fade " data-v-submenu>
                <li className="nav-item " data-v-submenu-item>
                  <a href="/registrations" className="nav-link " data-v-submenu-item-url>
                    <i className="la la-database" data-v-submenu-item-icon></i>
                    <span data-v-submenu-item-name>View Registrations</span>
                  </a>
                </li>
                <li className="nav-item " data-v-submenu-item>
                  <a href="/registrations/add" className="nav-link " data-v-submenu-item-url>
                    <i className="la la-plus" data-v-submenu-item-icon></i>
                    <span data-v-submenu-item-name>New Rica Registration</span>
                  </a>
                </li>
       
              </ul>
            </li>

            <li className="nav-item" data-v-menu-item>
              <a className="nav-link d-none d-md-block " href="/locations" data-v-menu-item-url>
                <i className="icon-map-outline"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>Locations</span>
              
                <span className="badge badge-primary"></span>
              </a>
    
              <a className="nav-link mobile d-block d-sm-none" href="/locations" data-v-menu-item-url>
                <i className="icon-map-outline"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>Locations</span>
                
                <span className="badge badge-primary"></span>
              </a>
            </li>

            <li className="nav-item" data-v-menu-item>
              <a className="nav-link d-none d-md-block " href="/networks" data-v-menu-item-url>
                <i className="icon-wifi-outline"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>Networks</span>
              
                <span className="badge badge-primary"></span>
              </a>
    
              <a className="nav-link mobile d-block d-sm-none" href="/networks" data-v-menu-item-url>
                <i className="icon-wifi-outline"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>Networks</span>
                
                <span className="badge badge-primary"></span>
              </a>
            </li>

            <li className="nav-item" data-v-menu-item>
              <a className="nav-link d-none d-md-block " href="/devices" data-v-menu-item-url>
                <i className="icon-cloud-circle"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>Devices</span>
              
                <span className="badge badge-primary"></span>
              </a>
    
              <a className="nav-link mobile d-block d-sm-none" href="/devices" data-v-menu-item-url>
                <i className="icon-cloud-circle"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>Devices</span>
                
                <span className="badge badge-primary"></span>
              </a>
            </li>


            <li className="nav-item" data-v-menu-item>
              <a className="nav-link d-none d-md-block" href="/ussd" data-v-menu-item-url>
                <i className="icon-push-sharp"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>USSD Registration</span>
              
                <span className="badge badge-primary"></span>
              </a>
    
              <a className="nav-link mobile d-block d-sm-none" href="/ussd">
                <i className="icon-push-sharp"></i>
                <img src="#" className="icon-img" />
                <span className="title" data-v-menu-item-name>USSD Registration</span>
                
                <span className="badge badge-primary"></span>
              </a>
            </li>




            
          </ul>
        </div>
    
    
      </nav> );
    }
}

export default Sidebar;