import axios from "axios";
import Config from "../config";
import { useSelector,useDispatch } from "react-redux";
import { forcelogout } from "../components/forcelogout";

const getadminurl=Config.rooturl+"/api/v1/Admin/Fetch/All";


function GetAllAdmin(id){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Admin/Fetch/All";
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{
        
        
    }
    return res.data;
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}

function GetAdminById(id){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Admin/FetchById/"+id;
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{
        
        
    }
    return res.data;
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}

function DeleteAdminById(id){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Admin/Delete/"+id;
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{
        
        
    }
    return res.data;
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}



function CreateAdmin(username,password){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Admin/Create";
    console.log(headers);
    return axios.post(url,{"username":username,"password":password},headers).then((res)=>{
        
        
        if(res.status==201){
        return res.data;
    }
    else{
        
        
    }
    return res.data;
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}

function UpdatePassword(id,password){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    var data={"id":id,"password":password}
    const url=Config.rooturl+"/api/v1/Admin/UpdatePassword";
    console.log(headers);
    return axios.post(url,data,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}

export{
    getadminurl,
    GetAdminById,
    GetAllAdmin,
    CreateAdmin,
    DeleteAdminById,
    UpdatePassword
}