import React from "react";



class TopHeader extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
       
    }



    render(){


        return( <div className="top-header">

        {/*<div className="btn-group" data-v-component-sites>
          <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">

            <i className="icon-eye-outline"></i>
            <span>View live website</span>
          </button>


          <div className="dropdown-menu">
            <div data-v-site>
              <a className="dropdown-item d-flex" href="#" data-v-site-url target="_blank">
                
                <span data-v-site-name className="flex-grow-1">Default website</span>
                <i className="la la-external-link-alt"></i>
              </a>
            </div>

            <div data-v-site>
              <a className="dropdown-item" href="#" data-v-site target="_blank">
                
                <span data-v-site-name className="flex-grow-1">Blog</span>
                <i className="la la-external-link-alt"></i>
              </a>
            </div>

            <div className="dropdown-divider"></div>

            <a className="dropdown-item" href="#" data-v-url-params='{"module":"settings/sites"}'>
              <span className="flex-grow-1">Manage sites</span>
              <i className="la la-edit ms-2"></i>
            </a>
          </div>
        </div>

        <div className="btn-group">
          <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="icon-reload-circle-outline"></i>
            <span>Clear cache</span>
          </button>

          <div className="dropdown-menu">
            <div>
              <a className="dropdown-item d-flex" href="#" target="_blank" data-v-url-params='{"module":"tools/cache", "action":"asset"}' data-v-vvveb-action="cache.clear">
                <div className="flex-grow-1">
                  <i className="icon-document-text-outline me-2 text-body-tertiary"></i>
                  <span>Assets</span>
                </div>
                <i className="la la-circle-notch ms-2"></i>
              </a>
            </div>
            <div>
              <a className="dropdown-item d-flex" href="#" target="_blank" data-v-url-params='{"module":"tools/cache", "action":"template"}' data-v-vvveb-action="cache.clear">
                <div className="flex-grow-1">
                  <i className="icon-code-slash-outline me-2 text-body-tertiary"></i>
                  <span>Templates</span>
                </div>
                <i className="la la-circle-notch ms-2"></i>
              </a>
            </div>
            <div>
              <a className="dropdown-item d-flex" href="#" target="_blank" data-v-url-params='{"module":"tools/cache", "action":"database"}' data-v-vvveb-action="cache.clear">
                <div className="flex-grow-1">
                  <i className="icon-server-outline me-2 text-body-tertiary"></i>
                  <span>Database</span>
                </div>
                <i className="la la-circle-notch ms-2"></i>
              </a>
            </div>
            <div>
              <a className="dropdown-item d-flex" href="#" target="_blank" data-v-url-params='{"module":"tools/cache", "action":"page"}' data-v-vvveb-action="cache.clear">
                <div className="flex-grow-1">
                  <i className="icon-document-outline me-2 text-body-tertiary"></i>
                  <span>Page cache</span>
                </div>
                <i className="la la-circle-notch ms-2"></i>
              </a>
            </div>
            <div>
              <a className="dropdown-item d-flex" href="#" target="_blank" data-v-url-params='{"module":"tools/cache", "action":"image"}' data-v-vvveb-action="cache.clear">
                <div className="flex-grow-1">
                  <i className="icon-image-outline me-2 text-body-tertiary"></i>
                  <span>Image cache</span>
                </div>
                <i className="la la-circle-notch ms-2"></i>
              </a>
            </div>
            <div>
              <a className="dropdown-item d-flex" href="#" target="_blank" data-v-url-params='{"module":"tools/cache", "action":"model"}' data-v-vvveb-action="cache.clear">
                <div className="flex-grow-1">
                  <i className="icon-paper-plane-outline me-2 text-body-tertiary"></i>
                  <span>Model cache</span>
                </div>
                <i className="la la-circle-notch ms-2"></i>
              </a>
            </div>
            <div className="dropdown-divider"></div>

            <div>
              <a className="dropdown-item d-flex" href="#" target="_blank" data-v-url-params='{"module":"tools/cache", "action":"delete"}' data-v-vvveb-action="cache.clear">
                <div className="flex-grow-1">
                  <i className="icon-sync-outline me-2 text-body-tertiary"></i>
                  <span>All cache</span>
                </div>
                <i className="la la-circle-notch ms-2"></i>
              </a>
            </div>

          </div>
        </div>
*/}

        <div className="float-end">

        {/**   <div className="btn-group top-notifications" data-v-component-notifications>
            <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="la la-bell"></i>
              <span className="badge bg-primary-subtle text-body" data-v-if="count > 0" data-v-notification-count>5</span>
            </button>

            <div className="dropdown-menu">
              <div data-v-if="notifications.updates.core.hasUpdate">
                <h6 className="dropdown-header">Updates</h6>
                <a className="dropdown-item" href="#" data-v-site-href data-v-url-params='{"module":"tools/update"}'>
                  <i className="icon-flash-outline text-success"></i>
                  <span>Update available</span>
                  <strong className="badge bg-success small mx-2" data-v-notification-updates-core-version>0.0.5</strong>
                </a>
                <div className="dropdown-divider"></div>
              </div>
              <div data-v-if-not="notifications.updates.core.hasUpdate">
                <h6 className="dropdown-header">Updates</h6>
                <a className="dropdown-item" href="#" data-v-site-href data-v-url-params='{"module":"tools/update"}'>
                  <i className="icon-flash-outline"></i>
                  <span>Up to date</span>
                  <strong className="badge bg-primary small mx-2" data-v-notification-updates-core-version>0.0.5</strong>
                </a>
                <div className="dropdown-divider"></div>
              </div>

              <div data-v-group>
                <div>
                  <h6 className="dropdown-header" data-v-group-name data-filter-capitalize>Sales</h6>
                </div>
                <div className="dropdown-item" data-v-group-notification>
                  <a href="#" data-v-group-notification-url>
                    <i className="" data-v-group-icon></i>
                    <span data-v-group-notification-name>New orders</span>
                    <strong className="badge bg-primary-subtle mx-2 bg-primary-subtle" data-v-group-notification-count>5</strong>
                  </a>
                </div>
                <div className="dropdown-item" data-v-group-notification>
                  <a href="#" data-v-site-href data-v-url-params='{"module":"order/orders"}'>
                    <i className="la la-file-invoice" data-v-group-icon></i>
                    <span data-v-group-notification-name>Returns</span>
                    <strong className="badge bg-danger-subtle small" data-v-group-notification-count>2</strong>
                  </a>
                </div>
                <div className="dropdown-divider"></div>
              </div>

              <div data-v-group>
                <div className="dropdown-divider"></div>
                <div>
                  <h6 className="dropdown-header" data-v-group-name data-filter-capitalize>Sales</h6>
                </div>
                <div className="dropdown-item" data-v-group-notification>
                  <a href="#" data-v-group-notification-url>
                    <i className="la la-comment"></i>
                    <span data-v-group-notification-name>New comments</span>
                    <strong className="badge bg-primary-subtle small mx-2" data-v-notification-value>5</strong>
                  </a>
                </div>
                <div className="dropdown-item" data-v-group-notification>
                  <a href="#" data-v-group-notification-url>
                    <i className="la la-comment"></i>
                    <span data-v-group-notification-name>Spam comments</span>
                    <strong className="badge bg-danger-subtle small" data-v-notification-value>2</strong>
                  </a>
                </div>
              </div>

              <div data-v-group>
                <div className="dropdown-divider"></div>
                <div>
                  <h6 className="dropdown-header" data-v-group-name data-filter-capitalize>Products</h6>
                </div>
                <div className="dropdown-item" data-v-group-notification>
                  <a href="#" data-v-group-notification-url>
                    <i className="la la-comment"></i>
                    <span data-v-group-notification-name>New reviews</span>
                    <strong className="badge bg-primary-subtle small mx-2" data-v-notification-value>5</strong>
                  </a>
                </div>
                <div className="dropdown-item" data-v-group-notification>
                  <a href="#" data-v-site-href data-v-url-params='{"module":"content/comments"}'>
                    <i className="la la-comment"></i>
                    <span data-v-group-notification-name>Spam questions</span>
                    <strong className="badge bg-danger-subtle small" data-v-notification-value>2</strong>
                  </a>
                </div>
              </div>

            </div>
          </div>

          <div className="btn-group" data-v-component-sites>
            <form method="post">
              <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">

                <i className="la la-sitemap me-1"></i>
                <span data-v-site-info-name>Default site</span>
              </button>


              <div className="dropdown-menu">
                <div data-v-site>
                  <button className="dropdown-item" href="#" data-v-site-site_id name="site" value="default">
                    <i className="la la-window-minimize la-lg me-2" data-v-if="state = 'live'" data-v-site-icon></i>
                    <i className="la la-lg me-2" data-v-if-not="state = 'live'" data-v-site-icon></i>
                    <span data-v-site-name>Default website</span>
                  </button>
                </div>

                <div data-v-site>
                  <a className="dropdown-item" href="#" data-v-site name="site" value="default">
                    <i className="la la-window-minimize la-lg me-2" data-v-site-icon></i>
                    <i className="la la-lg me-2" data-v-if-not="state = 'live'" data-v-site-icon></i>
                    <span data-v-site-name>Blog</span>
                  </a>
                </div>

                <div className="dropdown-divider"></div>

                <a className="dropdown-item" href="#" data-v-url-params='{"module":"settings/site"}'>
                  <i className="la la-plus me-2"></i>
                  Add new
                </a>
              </div>
            </form>
          </div>

          <div className="btn-group" data-v-component-states>
            <form method="post">
              <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-v-className-if-not-bg-danger-subtle="states.active = 'live'">

                <i className="la text-success me-1" data-v-state-info-active_icon></i>
                <span data-v-state-info-active_name>Live</span>
              </button>


              <div className="dropdown-menu">
                <div data-v-state>
                  <button className="dropdown-item" href="#" onclick="return confirm('Are you sure you want to set your website to `' + dataset.state + '` state?')" name="state" data-state="live">
                    <i className="la la-broadcast-tower la-lg me-2" data-v-state-icon></i>
                    <span data-v-state-name>
                      Live
                    </span>
                  </button>
                </div>
                <div data-v-state>
                  <button className="dropdown-item" href="#" onclick="return confirm('Are you sure you want to set your website to maintenance?')" name="state" data-state="maintenance">
                    <i className="la la-wrench la-lg me-2" data-v-state-icon></i>
                    <span data-v-state-name>
                      Maintenance
                    </span>
                  </button>
                </div>
                <div data-v-state>
                  <button className="dropdown-item" href="#" onclick="return confirm('Are you sure you want to set your website to coming soon mode?')" name="state" data-state="coming soon">
                    <i className="la la-clock la-lg me-2" data-v-state-icon></i>
                    <span data-v-state-name>
                      Coming soon
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="btn-group" data-v-component-languages>
            <form method="post">
              <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">

                <i className="la la-flag me-1"></i>
                <span data-v-language-info-name>English</span>
              </button>


              <div className="dropdown-menu">

                <div data-v-language>
                  <button className="dropdown-item" href="#" name="language" data-v-language-code>
                    <i className="la la-flag la-lg me-2"></i>
                    <span data-v-language-name>English</span>
                  </button>
                </div>

                <div data-v-language>
                  <button className="dropdown-item" href="#" name="language" data-v-language-code>
                    <i className="la la-flag la-lg me-2"></i>
                    <span data-v-language-name>Romanian</span>
                  </button>
                </div>

                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#" data-v-url-params='{"module":"localization/languages"}'>
                  <i className="la la-plus me-2"></i>
                  Add new
                </a>
              </div>
            </form>
          </div>

          <div className="vr align-middle mx-1"></div>


          <a target="_blank" id="top-docs-btn" href="&action=goToHelp" onclick="this.href = window.location.href.replace('action', 'origaction') + (window.location.href.indexOf('?') > 0 ? '&' : '?') + 'action=goToHelp'" className="btn" aria-expanded="false">
            <i className="la la-lg la-question-circle"></i>
            <span>Docs</span>
          </a>

*/}
          <div className="btn-group" data-v-component-admin>

            


              <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i className="la la-user"></i>
                <span data-v-admin-display_name>Admin</span>
                <img className="ms-2 rounded-circle" src="" height="24" data-v-admin-avatar />
              </button>

              <div className="dropdown-menu dropdown-menu-end">
               
                {/*<a className="dropdown-item" href="#" data-v-admin-edit-url>
                  <span>Edit Profile</span>
                </a>
                <div className="dropdown-divider"></div>
                */}
                <div className="dropdown-item">

                  <input type="hidden" name="logout" />

                  <span className="btn-text"><a href="/logout">  Logout  </a></span>

                </div>
              </div>

           
          </div>

        </div>
      </div>);
    }
}


export default TopHeader;