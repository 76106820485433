import axios from "axios";
import Config from "../config";
import { useSelector,useDispatch } from "react-redux";
import { forcelogout } from "../components/forcelogout";
const getsimpackurl=Config.rooturl+"/api/v1/SimPack/Fetch/All";
const uploadsimpackurl=Config.rooturl+"/api/v1/SimPack/Upload";
function GetSimPack(){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    
    console.log(headers);
    return axios.get(getsimpackurl,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    }) 
}


function UploadSimPack(formData){
    const token = localStorage.getItem("token");
    var headers={headers:{Authorization:"Bearer "+token, "Content-Type": 'multipart/form-data'}};
    
    console.log(headers);

    
    return axios.post(uploadsimpackurl, formData,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    }) 
}
export{
    getsimpackurl,
    uploadsimpackurl,
    GetSimPack,
    UploadSimPack
}