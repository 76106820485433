import axios from "axios";
import Config from "../config";
import { useSelector,useDispatch } from "react-redux";
import { forcelogout } from "../components/forcelogout";

function GetLocations(){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Location/Fetch/All";
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    }) 
}

export{
    GetLocations
}