import React from 'react';
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import {getcredentialsurl,GetAllCredentials,UpdateStatus,UpdatePassword} from "../../services/api.credentials";
import TopHeader from '../../components/topheader';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
//import DT from 'datatables.net-bs5';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import { Card, CardBody, CardHeader, CardTitle,Dropdown,Modal,Form,Button } from 'react-bootstrap';
import { Navigate } from 'react-router';

const columns = [
  { data: 'id' },
  { data: 'network' },
  { data: 'username' },
  { data: 'password' },
  { data: 'groupName' },
  { data: 'status' },
  { data: 'id' },
  
];


class ChangePassword extends React.Component{

  constructor(props){
    super(props);
    this.props = props;
    //console.log("Props ")
    //console.log(this.props)
    this.state={id:this.props.id,visible:this.props.visible,username:this.props.username,password:this.props.password,password2:this.props.password2,id:this.props.id}
    this.handleClose= this.handleClose.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePassword2 = this.onChangePassword2.bind(this);
   
    //console.log("Modal should show")
  }

  handleClose(e){
   
    this.props.updateState(e)
  }

  changePassword(){
    UpdatePassword(this.state.id,this.state.password).then(res=>{
      this.props.updateState()
      //this.props.reload();
    })
  }

  onChangePassword(e){
    this.setState({password:e.target.value})
  }
  onChangePassword2(e){
    this.setState({password2:e.target.value})
  }

  updateForm(e){
    console.log("Form updated")
  }
  render(){

    return(
     
      <Modal show={this.state.visible} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <Form>
        <Form.Group className="mb-3" controlId="username">
        <Form.Label>Username</Form.Label>
        <Form.Control type="text" value={this.state.username} readonly />
      </Form.Group>
      <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control type="text" value={this.state.password} onChange={this.onChangePassword} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="password2">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control type="text"  value={this.state.password2} onChange={this.onChangePassword2} />
      </Form.Group>
    </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary" onClick={this.changePassword}>Update Password</Button>
        </Modal.Footer>
      </Modal>
     
    );

  }
   
}


class AgentDropDown extends React.Component{

  constructor(props){
    super(props);
    //console.log(this.props.rows.id)
    this.props = props;
    this.state={agentid:this.props.rows.id}
    this.updateUserStatus = this.updateUserStatus.bind(this);
    this.updateCredentialsStatus = this.updateCredentialsStatus.bind(this);
    //console.log(this.props.rows)
   
  }

  updateMain(e){
    this.props.updateForm(e);
   }

   showChangePassword(e){
    this.updateMain(e);
   }

  updateUserStatus(){
    console.log(this.state)
    UpdateStatus(this.state.agentid).then(res=>{
      console.log(res);
      this.props.reload();
    })
  }

  updateCredentialsStatus(){
    console.log(this.state)
    UpdateStatus(this.state.agentid).then(res=>{
      console.log(res);
      this.props.reload();
    })
  }

  updatePassword(){
    console.log(this.state)
    UpdatePassword(this.state.agentid).then(res=>{
      console.log(res);
    })
  }

  render(){
    return (<Dropdown>
      <Dropdown.Toggle>
      Action
      <span className='caret'></span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
      <Dropdown.Item onClick={this.updateUserStatus}>Change Status</Dropdown.Item>
     
      <Dropdown.Item  onClick={e=>{
         
        window.location.href="/credentials/update/"+this.props.rows.id
      }}>Update Credentials</Dropdown.Item>
      <Dropdown.Item onClick=''>Delete Credentials</Dropdown.Item>
      </Dropdown.Menu>
      </Dropdown>);
      }
}


class Credentials extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
        const token = localStorage.getItem("token")
        var headers= {Authorization:"Bearer "+token};
        this.state={"visible":false,"redirect":"",data:[],"module":"credentials","headers":headers,"loading":true}
        this.updateVisibility = this.updateVisibility.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.setUsername = this.setUsername.bind(this);
        this.goToAddCredentials = this.goToAddCredentials.bind(this);
        DataTable.use(DT);

    }

    componentDidMount(){

    }

    UpdateState(e){
      console.log("State updated here");
      console.log(e)
      this.setState({"visible":true})
    }

    updateVisibility(){
      this.setState({visible:false})
    }
    reloadData(){
     this.getCredentials()
    }

    setUsername(e){
      console.log("Fire 1")
      this.setState({username:e.username})
     }
 
  getCredentials(){
      GetAllCredentials().then(res=>{
        console.log(res)
        this.setState({"data":res})
      })
  }

  goToAddCredentials(){
    this.setState({redirect:"addcredentials"});
  }



    render(){
      console.log(getcredentialsurl)
      var changepasswordview=<div></div>
      if(this.state.visible){
        changepasswordview=<ChangePassword  visible={this.state.visible} username={this.state.username} id={this.state.id} updateState={this.updateVisibility} reload={(e)=>{this.reloadData(e);}} />

      }
      if(this.state.redirect=="addcredentials"){
         return <Navigate to="add"  />
      }
        return (<div>



            <div id="container">
            
             
                
            <div className="sidebar">
            
            <div className="logo">
            
            
            <div className="logo-content">
                   <img src={logo}   />
                    
                   
                </div>
            
            </div>
            
            
            <Sidebar />
            
            </div>
                <div className="main">
            
                <TopHeader />
            
            
                  <div id="content">
            
            
                    <div className="actions">
            
                      <div className="title">
                        <h4 className="text-muted my-2">
                          <i className="icon-person-outline"></i>
                          <span>Credentials</span>
                        </h4>
                      </div>
            
                      <div className="btns">
                        <button type="button" data-bs-toggle="collapse" aria-controls="filters" title="Filter" className="btn btn-outline-secondary me-2">
                          <i className="icon-funnel-outline"></i>&nbsp;
                          <i className="icon-chevron-down-outline small"></i>
                        </button>
            
                        <a  className="btn btn-primary btn-icon ms-1" onClick={this.goToAddCredentials}>
                          <i className="icon-add-circle-outline"></i>
                          <span>Add credentials</span>
                        </a>
                      </div>
            
                    </div>
            
            
                    <div id="filters" className="collapse mx-3">
                      
            
                       
                        <div className="card card-block p-3">
            
                          <div className="row">
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label">Search</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[search]"  placeholder="User name" id="search" />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label">Email</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[email]" placeholder="Email" id="Email" />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label">Phone number</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[phone_number]" placeholder="Phone number" id="phone_number" />
                                </div>
                              </div>
                            </div>
            
            
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" >Status</label>
                                <div className="col-sm-10">
                                  <select name="filter[status]" id="filter-by-status" className="form-control">
                                    <option value="">- All -</option>
                                    <option value="active" data-v-option>Active</option>
                                    <option value="inactive" data-v-option>Inactive</option>
                                  </select>
                                </div>
                              </div>
                            </div>
            
                            <div className="col-sm-2">
                              <button type="submit" id="button-filter" className="btn btn-primary btn-icon">
                                <i className="icon-funnel-outline"></i> Filter
                              </button>
                            </div>
                          </div>
            
                        </div>
                      
                    </div>
            
                    <div id="main-content">
            
                      <div className="users card-block" data-v-users>
                        
            
                          <div className="bulk-actions px-2 mb-2" style={{display:"none"}}>
                            
                            <button type="submit" name="action"  aria-expanded="false" title="Delete selected" className="btn btn-outline-secondary bg-body-tertiary text-danger">
                              <i className="icon-trash-outline"></i> Delete selected
                            </button>
                          </div>
            
                          <Card>
  <CardHeader>Credentials</CardHeader>
  <CardBody>

 
  <DataTable 
            
            ajax={{
              serverSide: true,
              url:getcredentialsurl,
              headers:this.state.headers,
              type:'POST'
              
            }}

            columns={columns} 
              className="display"
              
              slots={{
             
                5: (data, row) => (
                  
                  data==1?  "Active"
                  :
                      "Disabled" 
              
                ),
                6: (data, row) => (
                 
                    <AgentDropDown data={data} rows={row} setUsername={(e)=>{this.setUsername(e)}} updateMain={(e)=>{this.UpdateState(e);}} reload={(e)=>{this.reloadData(e);}}/>
                )
            }}

            options={{
                responsive: true,
                select: true,
                processing:true,
                serverSide:true,
                pageLength: 10
            }}>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Network</th>
                    <th>Username</th>
                    <th>Password</th>
                    <th>GroupName</th>
                    <th>Status</th>
                    <th>Action</th>
                  
                   
                </tr>
            </thead>
        </DataTable>

 
 

       
        </CardBody>
        </Card>

                      </div>
            
            
            
                     
                      
                    </div>
            
            
                  </div>
            
            
                </div>
              </div>
            {changepasswordview}
            
                    </div>);

    }
}


export default Credentials;