import React from 'react';
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import {getdeviceurl,GetDevices} from "../../services/api.devices";
import TopHeader from '../../components/topheader';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
//import DT from 'datatables.net-bs5';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import { Card, CardBody, CardHeader, CardTitle } from 'react-bootstrap';

const columndevices = [
  { data: 'id' },
  { data: 'username' },
  { data: 'deviceIdentifier' },
  { data: 'deviceName' },
  { data: 'deviceOS' },
  { data: 'deviceVersion' },
  { data: 'isLocked' },
  { data: 'lockDate' },
  { data: 'createDate' },

];

class Devices extends React.Component{

    constructor(props){
        super(props);
        this.props = props;
        const token = localStorage.getItem("token")
        const headers= {Authorization:"Bearer "+token};
        this.state={"redirect":"",data:[],"module":"devices","headers":headers};
        DataTable.use(DT);
        
    }


    getDevices(){
        GetDevices().then(res=>{
          console.log(res)
        })
    }




    render(){

        return (<div>



<div id="container">

 
    
<div className="sidebar">

<div className="logo">


<div className="logo-content">
       <img src={logo}   />
        
       
    </div>

</div>


<Sidebar />

</div>
    <div className="main">

     
     <TopHeader />


      <div id="content">


        <div className="actions">

          <div className="title">
            <h4 className="text-muted my-2">
              <i className="icon-person-outline"></i>
              <span>Devices</span>
            </h4>
          </div>

          <div className="btns">
            <button type="button" data-bs-toggle="collapse" href="#filters" aria-expanded="false" aria-controls="filters" title="Filter" className="btn btn-outline-secondary me-2">
              <i className="icon-funnel-outline"></i>&nbsp;
              <i className="icon-chevron-down-outline small"></i>
            </button>

            <a href data-v-url-params='{"module":"user/user"}' className="btn btn-primary btn-icon ms-1">
              <i className="icon-add-circle-outline"></i>
              <span>Add Device</span>
            </a>
          </div>

        </div>


        <div id="filters" className="collapse mx-3">
          

           
            <div className="card card-block p-3">

              <div className="row">
                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="search">Search</label>
                    <div className="col-sm-10">
                      <input type="search" className="form-control" name="filter[search]" value="" placeholder="User name" id="search" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="search">Email</label>
                    <div className="col-sm-10">
                      <input type="search" className="form-control" name="filter[email]" value="" placeholder="Email" id="Email" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="search">Phone number</label>
                    <div className="col-sm-10">
                      <input type="search" className="form-control" name="filter[phone_number]" value="" placeholder="Phone number" id="phone_number" />
                    </div>
                  </div>
                </div>


                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="input-model">Status</label>
                    <div className="col-sm-10">
                      <select name="filter[status]" id="filter-by-status" className="form-control">
                        <option value="">- All -</option>
                        <option value="active" data-v-option>Active</option>
                        <option value="inactive" data-v-option>Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-sm-2">
                  <button type="submit" id="button-filter" className="btn btn-primary btn-icon">
                    <i className="icon-funnel-outline"></i> Filter
                  </button>
                </div>
              </div>

            </div>
          
        </div>

        <div id="main-content">
 
          <div className="users card-block" data-v-users>
            

              <div className="bulk-actions px-2 mb-2" style={{display:"none"}}>
                
                <button type="submit" name="action" value="delete" aria-expanded="false" title="Delete selected" className="btn btn-outline-secondary bg-body-tertiary text-danger">
                  <i className="icon-trash-outline"></i> Delete selected
                </button>
              </div>

              <DataTable 
            
            ajax={{
              serverSide: true,
              url:getdeviceurl,
              headers:this.state.headers
              
            }}

            columns={columndevices} 
            className="display"
            slots={{
             6: (data, row) => (
                
                data==1?"Device Locked":"Active"
              )
          }}
            options={{
                responsive: true,
                select: true,
                processing:true
            }}>
             <thead>
            <tr>
                <th>Id</th>
                <th>Username</th>
                <th>Device Identifier</th>
                <th>Device Name</th>
                <th>OS Version</th>
                <th>Device Version</th>
                <th>Device Locked?</th>
                <th>Lock Date</th>
                <th>Add Date</th>
            </tr>
        </thead>
        </DataTable>  
            
    </div>



        

          
        </div>


      </div>


    </div>
  </div>


        </div>);

    }
}


export default Devices;