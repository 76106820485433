import React from 'react';
import  logo from "../../logo_large.png";
import {DoAuth} from "../../services/api.auth";
import {Navigate} from "react-router-dom";
import { Alert, Spinner } from 'react-bootstrap';
import { Margin } from '@mui/icons-material';

class Login extends React.Component{



    constructor(props){
        super(props);
        this.props = props;
        this.state={redirect:"",username:"",password:"",isloading:false,message:""};
        this.doLogin = this.doLogin.bind(this);
        this.handleUsername = this.handleUsername.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
    }

    doLogin(){
      this.setState({isloading:true})
      DoAuth(this.state.username,this.state.password).then(res=>{
         
          this.setState({isloading:false})
            if(res && res.token){
              console.log("Success");
              window.localStorage.setItem("user",JSON.stringify({"token":res.token}));
              this.setState({redirect:"dashboard"})
            }else{
              
              if(res && res.status && res.status==401){
                //console.log(res.data.message)
                this.setState({message:res.data.message})
              }
              if(res && res.status && res.status==700){
                console.log(res.data.message)
                this.setState({message:res.data.message})
              }

              setTimeout(()=>{
                this.setState({message:""})
              },5000);
            }
        })
    }


    handleUsername(event){
      console.log(event.target.value)
        this.setState({username:event.target.value});
    }

    handlePassword(event){
      console.log(event.target.value)
        this.setState({password:event.target.value});
    }

    render(){
      if(this.state.redirect =="dashboard"){
        return <Navigate to="/dashboard" />
      }
      var loadingview=<div></div>;
      var alertview=<div></div>
      if(this.state.isloading){
        loadingview=<Spinner animation="border"/>
      }
      if(this.state.message.length>0){
        alertview=<Alert variant="danger">{this.state.message}</Alert>
       
      }
        return(<div>
            <div className="align-items-center h-100 mx-auto my-5 d-flex">
    <div className="login-container">

      <div className="logo-content">
         <img src={logo}   />
          
         
      </div>


      <div id="login" className="login-form fade-in-effect">
        
       


          <div className="login-form-content">

                {alertview}

            <div className="mb-4">
              <label className="form-label">Username or email</label>
              <input type="text" className="form-control" name="user" id="username" value={this.state.username} onChange={this.handleUsername} />
            </div>

            <div className="mb-4">
              <label className="form-label d-flex justify-content-between align-items-center">
                <span>Password</span>
            </label>

              <div className="input-group">
                <input type="password"  className="form-control" id="password" name="password" value={this.state.password} onChange={this.handlePassword} />
                <div className="input-group-append">
                  <button className="btn px-3" type="button" >
                    <i className="icon-eye-off-outline"></i>
                  </button>
                </div>
              </div>

            </div>



            <div className="mt-4 row">


              <div>

                <button type="submit" className="btn btn-primary btn-icon w-100 btn-login" onClick={this.doLogin} >
                  
                  {loadingview}
                  <span className="button-text">
                    Log In <i className="icon-chevron-forward-outline float-end ms-2"></i>
                  </span>
                </button>

              </div>


              <div className="mt-3">

                <div className="form-check float-start">
                  <input className="form-check-input" type="checkbox" value="" id="rememberme" />
                  <label className="form-check-label" for="rememberme">
                    Remember me
                  </label>
                </div>

                <a  className="text-muted float-end">Forgot your password?</a>
              </div>

            </div>

            <div className="login-footer ">
          

            </div>
          </div>

         

        

      </div>

      </div>
  </div>
        </div>)
    }
}


class Logout extends React.Component{



  constructor(props){
    super(props);
    this.props = props;
    window.localStorage.clear();
  }
  render(){
    if(!window.localStorage.getItem("user")){
      return <Navigate to="/login" />
    }
    return (<div></div>);
  }

}
export {Login,Logout};