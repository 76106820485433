import React from 'react';
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import {getadminurl,GetAllAdmin,DeleteAdminById} from "../../services/api.admin";
import TopHeader from '../../components/topheader';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
//import DT from 'datatables.net-bs5';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import { Card, CardBody, CardHeader, CardTitle,Dropdown,Modal,Form,Button } from 'react-bootstrap';
import { Navigate } from 'react-router';

const columns = [
  { data: 'id' },
  { data: 'username' },
  { data: 'token' },
  { data: 'status' },
  { data: 'id' },

];

const token = localStorage.getItem("token")
const headers= {Authorization:"Bearer "+token};

class ChangePassword extends React.Component{

  constructor(props){
    super(props);
    this.props = props;
    this.state={visible:this.props.visible,username:this.props.username,id:this.props.id}
    console.log("Modal should show")
  }

  updateForm(e){
    console.log("Form updated")
  }
  render(){

    return(
     
      <Modal show={this.state.visible}>
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <Form>
      <Form.Group className="mb-3" controlId="username">
        <Form.Label>Username</Form.Label>
        <Form.Control type="text" value="" readonly />
      </Form.Group>
      <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password"  />
      </Form.Group>
    </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Update Password</Button>
        </Modal.Footer>
      </Modal>
     
    );

  }
   
}
class AdminDropDown extends React.Component{

  constructor(props){
    super(props);
    console.log(props);
    this.props = props;
    this.state={id:this.props.data,data:this.props.row}
  }

   updateMain(e){
    this.props.updateForm(e);
   }

  render(){
    return (<Dropdown>
      <Dropdown.Toggle> Action </Dropdown.Toggle>
      <Dropdown.Menu>
      <Dropdown.Item onClick={e=>{ 
        console.log(e); 
       window.location.href="/admin/changepassword/"+this.props.row.id;
         
        }}>Change Password</Dropdown.Item>
      <Dropdown.Item onClick={e=>{
        DeleteAdminById(this.props.row.id).then(res=>{

          alert("succefully deleted "+this.props.row.username);
          window.location.href="/admin";
        })
          
      }}>Delete Admin</Dropdown.Item>
      </Dropdown.Menu>
      </Dropdown>);
      }
}
class Admin extends React.Component{

  
    constructor(props){
        super(props);
        this.props = props;
        this.state={"redirect":"",data:null,"module":"admin","isloading":false,"visible":false,username:"",id:""}
        this.goToAddAdmin = this.goToAddAdmin.bind(this);
        DataTable.use(DT);
       
    }

    componentDidMount(){
     
      this.getAdmin();
    }

    UpdateState(e){
      console.log("State updated here");
      console.log(e)
      this.setState({"visible":true})
    }
/** 
    noData(){
      return (<tr data-v-if-not="this.count >= 1">
        <td colspan="100">
          <div className="text-center p-4 bg-body-tertiary border">
            <i className="icon-person-outline fs-1 mb-2"></i>
            <div className="mt-3">No {this.state.module} to display!</div>

            <a href data-v-url-params='{"module":"user/user"}' className="btn btn-primary btn-icon mt-3">
              <i className="icon-add-circle-outline"></i> Add {this.state.module}
            </a>

          </div>
        </td>
      </tr>)
    }


    renderList(admins){
    return admins.map((admin,i)=>{
      return (<tr>
        <td className="checkbox">
          <label className="form-check">
            <input type="checkbox" className="form-check-input" value="4" name="user_id[]" data-v-vvveb-action="table.selectRow" data-v-user_id />
            <span className="form-check-label"></span>
            <span className="custom-control-description">&nbsp;</span>
          </label>
        </td>

        <td className="img">
          {admin.id}
         
        </td>

        <td className="user">
          
            <span data-v-username>{admin.username}</span>
          
        </td>
        <td>
          <span className="small text-muted">{admin.token.substring(0,30)}........{admin.token.substring(-1,20)}</span>
        </td>
       
        <td>
          <span className="badge small">Active</span>
        </td>


        <td className="actions">

          <div className="btn-group">
            <a className="btn btn-outline-secondary" data-v-edit-url>
              <i className="la la-pencil-alt text-primary"></i>
              <span>Edit</span>
            </a>
            <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">

              <span className="visually-hidden">Toggle primary</span>
            </button>
            <ul className="dropdown-menu">

              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item text-danger" data-v-delete-url onclick="return confirm('Are you sure?')">
                  <i className="icon-trash-outline"></i>
                  <span>Delete</span>
                </a>
              </li>
            </ul>
          </div>

        </td>
</tr>);
    })

    }

    */

      getAdmin(){
        this.setState({isloading:true})
        GetAllAdmin().then(res=>{
          console.log(res)
          this.setState({isloading:false})
          if(res.length>0){
            this.setState({"data":res})


          }else{
            this.setState({"data":res})
          }
        })
    }

    goToAddAdmin(){
      this.setState({redirect:"addadmin"});
    }



    render(){
      if(this.state.redirect=="login"){

      }
      if(this.state.redirect=="addadmin"){
        return <Navigate to="add"  />
     }
      var view=<div></div>
      if(this.state.visible){
        view=<ChangePassword  visible={this.state.visible} username={this.state.username} id={this.state.id} />

      }

        return (<div>



<div id="container">

 
    
<div className="sidebar">

<div className="logo">


<div className="logo-content">
       <img src={logo}   />
        
       
    </div>

</div>


<Sidebar />

</div>
    <div className="main">

     
     <TopHeader />


      <div id="content">


        <div className="actions">

          <div className="title">
            <h4 className="text-muted my-2">
              <i className="icon-person-outline"></i>
              <span>Admin</span>
            </h4>
          </div>

          <div className="btns">
            <button type="button" data-bs-toggle="collapse" href="#filters" aria-expanded="false" aria-controls="filters" title="Filter" className="btn btn-outline-secondary me-2">
              <i className="icon-funnel-outline"></i>&nbsp;
              <i className="icon-chevron-down-outline small"></i>
            </button>

            <a className="btn btn-primary btn-icon ms-1" onClick={this.goToAddAdmin}>
              <i className="icon-add-circle-outline"></i>
              <span>Add admin</span>
            </a>
          </div>

        </div>


        <div id="filters" className="collapse mx-3">
          

           
            <div className="card card-block p-3">

              <div className="row">
                <div className="col-sm-6 mb-3">
                  <div className="row">
                    <label className="col-sm-2 col-form-label" for="search">Search</label>
                    <div className="col-sm-10">
                      <input type="search" className="form-control" value="" placeholder="Username" id="search" />
                    </div>
                  </div>
                </div>
               

                <div className="col-sm-2">
                  <button type="submit" id="button-filter" className="btn btn-primary btn-icon">
                    <i className="icon-funnel-outline"></i> Filter
                  </button>
                </div>
              </div>

            </div>
          
        </div>

        <div id="main-content">


          <div className="users card-block" data-v-users>
            

              <div className="bulk-actions px-2 mb-2" style={{display:"none"}}>
                
                <button type="submit" name="action" value="delete" aria-expanded="false" title="Delete selected" className="btn btn-outline-secondary bg-body-tertiary text-danger">
                  <i className="icon-trash-outline"></i> Delete selected
                </button>
              </div>

<Card>
  <CardHeader>Administrators</CardHeader>
  <CardBody>


 
              <DataTable 

              ajax={{
                url:getadminurl,
                headers:headers,
              }
              }  
            
                columns={columns} className="display"
              slots={{
                4: (data, row) => (
                  
                    <AdminDropDown data={data} row={row} updateMain={(e)=>{this.UpdateState(e);}}/>
                )
            }}
            options={{
                responsive: true,
                select: true,
                processing:true,
                serverSide:true

            }}>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Username</th>
                    <th>Token</th>
                    <th>Status</th>
                    <th>Actions</th>
                   
                </tr>
            </thead>
        </DataTable>
        </CardBody>
        </Card>
           
           
                     </div>
  
        </div>


      </div>


    </div>
  </div>
  {view}
        </div>);

    }
}


export default Admin;