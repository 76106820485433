import React from 'react';
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import  placeholder from "../../placeholder.svg";
import TopHeader from '../../components/topheader';


class AddUssd extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
        this.state={firstname:"",surname:"",phone:"",email:"",redirect:""}
        this.setFirstname = this.setFirstname.bind(this);
        this.setSurname = this.setSurname.bind(this);
    }

    setFirstname(evt){
      this.setState({firstname:evt.target.value})
    }

    setSurname(evt){
      this.setState({surname:evt.target.value})
    }



    render(){

        return(<div>



            <div id="container">
            
             
                
            <div className="sidebar">
            
            <div className="logo">
            
            
            <div className="logo-content">
                   <img src={logo}   />
                    
                   
                </div>
            
            </div>
            
            
            <Sidebar />
            
            </div>
                <div className="main">
            
                 
                 <TopHeader />
            
            
                  <div id="content">
            
            
                    <div className="actions">
            
                      <div className="title">
                        <h4 className="text-muted my-2">
                          <i className="icon-person-outline"></i>
                          <span>New Registrations</span>
                        </h4>
                      </div>
            
                       
            
                    </div>
            
            
                    <div id="filters" className="collapse mx-3">
                      
            
                       
                        <div className="card card-block p-3">
            
                          <div className="row">
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="search">Search</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[search]" value="" placeholder="User name" id="search" />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="search">Email</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[email]" value="" placeholder="Email" id="Email" />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="search">Phone number</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[phone_number]" value="" placeholder="Phone number" id="phone_number" />
                                </div>
                              </div>
                            </div>
            
            
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="input-model">Status</label>
                                <div className="col-sm-10">
                                  <select name="filter[status]" id="filter-by-status" className="form-control">
                                    <option value="">- All -</option>
                                    <option value="active" data-v-option>Active</option>
                                    <option value="inactive" data-v-option>Inactive</option>
                                  </select>
                                </div>
                              </div>
                            </div>
            
                            <div className="col-sm-2">
                              <button type="submit" id="button-filter" className="btn btn-primary btn-icon">
                                <i className="icon-funnel-outline"></i> Filter
                              </button>
                            </div>
                          </div>
            
                        </div>
                      
                    </div>
            
                    <div id="main-content">
           
              
  
 

        <div id="main-content" className="px-4">

          

          
        <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Firstname</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" value={this.state.firstname} onChange={this.setFirstname} />
            </div>
          </div>
          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Surname</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" value={this.state.surname} onChange={this.setSurname} />
            </div>
          </div>
          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Phone</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" value={this.state.phone} onChange={this.setPhone} />
            </div>
          </div>

          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Identification Type</label>
            <div className="col-sm-10">
             <select className="form-control" onSelect={this.setSelected}>
              <option>National Identification</option>
              <option>Passport</option>
             </select>
            </div>
          </div>

          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Identification Number</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" value={this.state.idnumber} onChange={this.setIdNumber} />
            </div>
          </div>


    
          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Network</label>
            <div className="col-sm-10">
             <select className="form-control" onSelect={this.setSelected}>
              <option>MTN</option>
              <option>Telkom</option>
              <option>Vodacom</option>
              <option>CellC</option>
             </select>
            </div>
          </div>

          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Address 1</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" value={this.state.idnumber} onChange={this.setIdNumber} />
            </div>
          </div>

          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Address 2</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" value={this.state.idnumber} onChange={this.setIdNumber} />
            </div>
          </div>

          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Address 3</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" value={this.state.idnumber} onChange={this.setIdNumber} />
            </div>
          </div>

          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Country</label>
            <div className="col-sm-10">
             <select className="form-control" onSelect={this.setSelected}>
             <option>South Africa</option>
              
             </select>
            </div>
          </div>

          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Postal Code</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" value={this.state.idnumber} onChange={this.setIdNumber} />
            </div>
          </div>

          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">City/Town</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" value={this.state.idnumber} onChange={this.setIdNumber} />
            </div>
          </div>


          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Region</label>
            <div className="col-sm-10">
              <input type="text" className="form-control" value={this.state.idnumber} onChange={this.setIdNumber} />
            </div>
          </div>

          <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Suburb</label>
            <div className="col-sm-10">
             <select className="form-control" onSelect={this.setSelected}>
             <option>Johannesburg</option>
              
             </select>
            </div>
          </div>
          
          <div className="mb-3 mx-5  row"> 
                    <a href data-v-url-params='{"module":"user/user"}' className="btn btn-primary btn-icon ms-1">
                                    <i className="icon-add-circle-outline"></i>
                                    <span>Register Sim Pack</span>
                                    </a>
               </div>
        </div>
                
                
                </div>
  
                      
                    </div>
            
            
                  </div>
            
            
                </div>
             
            
            
                    </div>);
    }
}

export default AddUssd;