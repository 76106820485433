import axios from "axios";
import Config from "../config";
import { useSelector,useDispatch } from "react-redux";
import { forcelogout } from "../components/forcelogout";
const getregistrationurl=Config.rooturl+"/api/v1/Registration/Fetch/All";
const getrecentregistrationurl=Config.rooturl+"/api/v1/Registration/Fetch/Recent";
function GetRegistration(){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    
    console.log(headers);
    return axios.get(getregistrationurl,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    }) 
}

export{
    getregistrationurl,
    getrecentregistrationurl,
    GetRegistration
}