import React from "react";
import axios from "axios";
import Config from "../config";
//import { useSelector,useDispatch } from "react-redux";
//import { tokenSlice } from "../features/token/tokenSlice";
export function DoAuth(username,password){
   
    var data={
        username:username,
        password:password
    };

    const url=Config.rooturl+"/api/v1/Admin/Auth";
  
    return axios.post(url,data).then((res)=>{
        
        
        if(res && res.status && res.status==200){
        localStorage.setItem("token",res.data.token);
        return res.data;
    } 
    else{

    }
    }).catch((e)=>{
         
        if(e.response && e.response.status && e.response.status==401){
            console.log("Invalid username/password")
            let res={status:401,data:{"message":"Invalid username/password"}}
            return res;
        }else{
            let res={status:700,data:{"message":"Please check your network and try again"}}
            return res;
        }
       
    })
}

 