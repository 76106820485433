import React from "react";
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import TopHeader from '../../components/topheader';
import {GetNetwork,networkurl} from "../../services/api.networks";
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';

import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import { Card, CardBody, CardHeader, CardTitle } from 'react-bootstrap';

const columnsnetwork = [
  { data: 'id' },
  { data: 'name' },
  { data: 'logo'},
  { data: 'id' }

];




class Networks extends React.Component{

    constructor(props){
        super(props);
        this.props = props; 
        const token = localStorage.getItem("token")
        const headers= {Authorization:"Bearer "+token};
        this.state={"redirect":"",data:[],"module":"network","headers":headers}
        DataTable.use(DT);

    }


    componentDidMount(){
       
    }


     
  
   



    render(){


        return(<div>



            <div id="container">
            
             
                
            <div className="sidebar">
            
            <div className="logo">
            
            
            <div className="logo-content">
                   <img src={logo}   />
                    
                   
                </div>
            
            </div>
            
            
            <Sidebar />
            
            </div>
                <div className="main">
            
                <TopHeader />
            
            
                  <div id="content">
            
            
                    <div className="actions">
            
                      <div className="title">
                        <h4 className="text-muted my-2">
                          <i className="icon-person-outline"></i>
                          <span>Networks</span>
                        </h4>
                      </div>
            
                      <div className="btns">
                        
            
                        <a href data-v-url-params='{"module":"user/user"}' className="btn btn-primary btn-icon ms-1">
                          <i className="icon-add-circle-outline"></i>
                          <span>Add network</span>
                        </a>
                      </div>
            
                    </div>
            
            
                   
            
                    <div id="main-content">
           
                      <div className="users card-block" data-v-users>
                        
            
                          <div className="bulk-actions px-2 mb-2" style={{display:"none"}}>
                            
                            <button type="submit" name="action" value="delete" aria-expanded="false" title="Delete selected" className="btn btn-outline-secondary bg-body-tertiary text-danger">
                              <i className="icon-trash-outline"></i> Delete selected
                            </button>
                          </div>





            
                      <Card>
                        <CardHeader>Network</CardHeader>
                        <CardBody>

                      


                                                <DataTable
                                  ajax={{
                                    serverSide: true,
                                    url:networkurl,
                                    headers:this.state.headers
                                    
                                  }}

                                  columns={columnsnetwork} 
                                  className="display"

                                  slots={{
               
                                    2: (data, row) => (
                                     <img src={data} width={"100px"} height={"100px"} />
                                       
                                    )
                                    
                                    
                                }}
                                    
                                  options={{
                                      responsive: true,
                                      select: true,
                                  }}>
                                  <thead>
                                      <tr>
                                          <th>Id</th>
                                          <th>Name</th>
                                          <th>Logo</th>
                                           
                                        
                                      </tr>
                                  </thead>
                              </DataTable>
                                          
                              </CardBody>
                      </Card>

                 </div>



          

          
</div>


</div>


</div>
</div>


</div>);
    }
}


export default Networks;