import React from 'react';
import Sidebar from '../../components/sidebar';
import  logo from "../../logo.png";
import TopHeader from '../../components/topheader';
import  placeholder from "../../placeholder.svg";
import {UploadSimPack} from "../../services/api.simpack";

class AddSimPack extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
        this.state={file:null,filename:"",redirect:""}
        this.setFile = this.setFile.bind(this);
        this.doUpload = this.doUpload.bind(this);
    }

    
    doUpload(evt){
      const formData = new FormData();

      formData.append("file", this.state.file);
      UploadSimPack(formData).then(res=>{
        
        if(res.status=="ok"){

        alert(res.message);
         
        }

      })
    }

    setFile(evt){
      console.log(evt.target.files[0].name)
      this.setState({filename:evt.target.files[0].name,file:evt.target.files[0]})
    }

    render(){

        return(<div>



            <div id="container">
            
             
                
            <div className="sidebar">
            
            <div className="logo">
            
            
            <div className="logo-content">
                   <img src={logo}   />
                    
                   
                </div>
            
            </div>
            
            
            <Sidebar />
            
            </div>
                <div className="main">
            
                 
                 <TopHeader />
            
            
                  <div id="content">
            
            
                    <div className="actions">
            
                      <div className="title">
                        <h4 className="text-muted my-2">
                          <i className="icon-person-outline"></i>
                          <span>Upload SimPack</span>
                        </h4>
                      </div>
            
                       
            
                    </div>
            
            
                    <div id="filters" className="collapse mx-3">
                      
            
                       
                        <div className="card card-block p-3">
            
                          <div className="row">
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="search">Search</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[search]" value="" placeholder="User name" id="search" />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="search">Email</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[email]" value="" placeholder="Email" id="Email" />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="search">Phone number</label>
                                <div className="col-sm-10">
                                  <input type="search" className="form-control" name="filter[phone_number]" value="" placeholder="Phone number" id="phone_number" />
                                </div>
                              </div>
                            </div>
            
            
                            <div className="col-sm-6 mb-3">
                              <div className="row">
                                <label className="col-sm-2 col-form-label" for="input-model">Status</label>
                                <div className="col-sm-10">
                                  <select name="filter[status]" id="filter-by-status" className="form-control">
                                    <option value="">- All -</option>
                                    <option value="active" data-v-option>Active</option>
                                    <option value="inactive" data-v-option>Inactive</option>
                                  </select>
                                </div>
                              </div>
                            </div>
            
                            <div className="col-sm-2">
                              <button type="submit" id="button-filter" className="btn btn-primary btn-icon">
                                <i className="icon-funnel-outline"></i> Filter
                              </button>
                            </div>
                          </div>
            
                        </div>
                      
                    </div>
            
                    <div id="main-content">
           
              
  
 

        <div id="main-content" className="px-4">

          

          
        <div className="mb-3 row">
            <label for="input-site-title" className="col-sm-2 col-form-label">Filename</label>
            <div className="col-sm-10">
              <input type="file" className="form-control" placeholder="file" onChange={this.setFile} />
            </div>
          </div>
          

          
          
          <div className="mb-3 mx-5  row"> 
                    <a className="btn btn-primary btn-icon ms-1" onClick={this.doUpload}>
                                    <i className="icon-add-circle-outline"></i>
                                    <span>Upload SimPacks</span>
                                    </a>
               </div>
        </div>
                
                
                </div>
  
                      
                    </div>
            
            
                  </div>
            
            
                </div>
             
            
            
                    </div>);
    }
}

export default AddSimPack;