import {Navigate} from "react-router-dom";
import {useAuth} from "../components/useAuth";


export const ProtectedRoute =({children}) =>{
    //const {user} = useAuth() || {};
    const user = window.localStorage.getItem("user")
    //console.log(user)
    if(!user){
        return <Navigate to={"/login"} />;
    }
    return children;
}