import {useParams,createBrowserRouter} from "react-router-dom";
import {ProtectedRoute} from "./components/protectedroute";
import Home from "./pages/Home";
import {Login,Logout} from "./pages/Login"
import Dashboard from "./pages/Dashboard"
import Admin from "./pages/Admin";
import AddAdmin from "./pages/Admin/add";
import ChangePasswordAdmin from "./pages/Admin/changepassword";
import AddAdminRole from "./pages/Admin/addadminrole";
import Agent from "./pages/Agent";
import AddAgent from "./pages/Agent/add";
import UpdateAgent from "./pages/Agent/updateagent";
import ChangePasswordAgent from "./pages/Agent/changepassword";
import AddAgentRole from "./pages/Agent/addagentrole";
import Registrations from "./pages/Registrations";
import AddRegistrations from "./pages/Registrations/add";
import Credentials from "./pages/Credentials";
import AddCredentials from "./pages/Credentials/add";
import UpdateCredentials from "./pages/Credentials/update";
import Ussd from "./pages/Ussd";
import AddUssd from "./pages/Ussd/add";
import Networks from "./pages/Networks";
import Locations from "./pages/Locations";
import SimPack from "./pages/SimPacks";
import AddSimPack from "./pages/SimPacks/add";
import Devices from "./pages/Devices";
import ViewAgent from "./pages/Agent/view";

function ChangeAgentPasswordWithParams() {
   
    let { id } = useParams();
     
    return <ChangePasswordAgent id={id} />
  }

  function ChangeAdminPasswordWithParams() {
   
    let { id } = useParams();
     
    return <ChangePasswordAdmin id={id} />
  }
  function ViewAgentWithParams() {
   
    let { id } = useParams();
     
    return <ViewAgent id={id} />
  }


  function UpdateCredentialsWithParams() {
   
    let { id } = useParams();
     
    return <UpdateCredentials id={id} />
  }

const router= createBrowserRouter([
    {
        path:"/",
        element:<ProtectedRoute><Dashboard /></ProtectedRoute>
    },
    {
        path:"/login",
        element:<Login   />
    },
    {
        path:"/logout",
        element:<Logout   />
    },
    {
        path:"/dashboard",
        element:<ProtectedRoute><Dashboard /></ProtectedRoute>
    },
    {
        path:"/admin",
        element:<ProtectedRoute><Admin /></ProtectedRoute>
    },
    {
        path:"/admin/add",
        element:<ProtectedRoute><AddAdmin /></ProtectedRoute>
    },
    {
        path:"/admin/addrole",
        element:<ProtectedRoute><AddAdminRole /></ProtectedRoute>
    },
    {
        path:"/admin/changepassword/:id",
        element:<ProtectedRoute><ChangeAdminPasswordWithParams /></ProtectedRoute>
    },
    {
        path:"/agent",
        element:<ProtectedRoute><Agent /></ProtectedRoute>
    },
    {
        path:"/agent/add",
        element:<ProtectedRoute><AddAgent /></ProtectedRoute>
    },
    {
        path:"/agent/edit",
        element:<ProtectedRoute><UpdateAgent /></ProtectedRoute>
    },
    {
        path:"/agent/addrole",
        element:<ProtectedRoute><AddAgentRole /></ProtectedRoute>
    },
    {
        path:"/agent/changepassword/:id",
        element:<ProtectedRoute><ChangeAgentPasswordWithParams  /></ProtectedRoute>
    },
    {
        path:"/agent/view/:id",
        element:<ProtectedRoute><ViewAgentWithParams  /></ProtectedRoute>
    },
    {
        path:"/registrations",
        element:<ProtectedRoute><Registrations /></ProtectedRoute>
    },
    {
        path:"/registrations/add",
        element:<ProtectedRoute><AddRegistrations /></ProtectedRoute>
    },
    {
        path:"/networks",
        element:<ProtectedRoute><Networks /></ProtectedRoute>
    },
    {
        path:"/locations",
        element:<ProtectedRoute><Locations /></ProtectedRoute>
    },
    {
        path:"/simpack",
        element:<ProtectedRoute><SimPack /></ProtectedRoute>
    },
    {
        path:"/simpack/add",
        element:<ProtectedRoute><AddSimPack /></ProtectedRoute>
    },
    {
        path:"/devices",
        element:<ProtectedRoute><Devices /></ProtectedRoute>
    },
    {
        path:"/ussd",
        element:<ProtectedRoute><Ussd /></ProtectedRoute>
    },{
        path:"/credentials",
        element:<ProtectedRoute><Credentials /></ProtectedRoute>
    },
    {
        path:"/credentials/add",
        element:<ProtectedRoute><AddCredentials /></ProtectedRoute>
    },
    {
        path:"/credentials/update/:id",
        element:<ProtectedRoute><UpdateCredentialsWithParams /></ProtectedRoute>
    },
])

export default router;