import axios from "axios";
import Config from "../config";
import { useSelector,useDispatch } from "react-redux";
import { forcelogout } from "../components/forcelogout";


const getrecentagenturl=Config.rooturl+"/api/v1/Agent/Fetch/Recent";
const getagenturl=Config.rooturl+"/api/v1/Agent/Fetch/All";

function UpdateStatus(id){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Agent/UpdateStatus/"+id;
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}



function UpdateRicaStatus(id){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Agent/UpdateAgentStatus/"+id;
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}



function UpdatePassword(id,password){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    var data={"id":id,"password":password}
    const url=Config.rooturl+"/api/v1/Agent/UpdatePassword";
    console.log(headers);
    return axios.post(url,data,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}

function GetAgentById(id){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Agent/FetchById/"+id;
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}


function GetAgentDetailById(id){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Agent/FetchDetailById/"+id;
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}

function GetAllAgents(){
    const token = localStorage.getItem("token")
    var headers={headers:{Authorization:"Bearer "+token}};
    const url=Config.rooturl+"/api/v1/Agent/Fetch/All";
    console.log(headers);
    return axios.get(url,headers).then((res)=>{
        
        console.log(res);
        if(res.status==200){
        return res.data;
    }
    else{

    }
    }).catch(e=>{
        
        if(e.response.status==401){
            forcelogout()
        }
       
    })
}

export{
getagenturl,
    getrecentagenturl,
    GetAgentById,
    GetAgentDetailById,
    GetAllAgents,
    UpdateStatus,
    UpdatePassword,
    UpdateRicaStatus
}